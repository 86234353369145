import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import moment from 'moment'
import PubSub from "pubsub-js";
import getSymbolFromCurrency from 'currency-symbol-map'

import LoadingSpinner from "components/LoadingSpinner";
import PaymentInfoIcon from "icons/PaymentInfoIcon";
import ErrorMessage from "components/ErrorMessage";
import MusioCheckbox from "components/MusioCheckbox";

// import StartMembership from './StartMembership'
// import ActiveMembership from './ActiveMembership';
import DashboardPanel from "components/DashboardPanel";

import "./style.css";
import MusioButton from "components/MusioButton";

import {
    getLaunchCheckout,
    clearLaunchCheckout
} from "store";

import {
    isLoaded,
    getPaddlePaymentUpdateTxnId,
    startRecurring,
    planString,
    priceString,
    cancelRecurring,
    resumeRecurring,
    changeRecurring,
    prorateRecurring,
    couponRetrieval
} from "store/resources";

import {
  GetMusio1PriceId
} from "store/prices";
import { initializePaddle, Paddle } from '@paddle/paddle-js';

const SubscriptionPanel = (props) => {
    const [overlay, setOverlay] = useState("");
    const [overlayComponent, setOverlayComponent] = useState("");
    const recurring = useSelector((state) => state.recurring);
    const trial = useSelector((state) => state.trial);
    const licenses = useSelector((state) => state.licenses);
    const billing = useSelector((state) => state.billing);
    const coupons = useSelector((state) => state.coupons);
    const userId = useSelector((state) => state.userId);
    const userEmail = useSelector((state) => state.email);
    const loading =
        !isLoaded(recurring) ||
        !isLoaded(trial) ||
        !isLoaded(licenses) ||
        !isLoaded(coupons) ||
        !isLoaded(billing);

    const [referral, setReferral] = useState(null);
    // const [rewardfulCoupon, setRewardfulCoupon] = useState(null);
    const [paddle, setPaddle] = useState(null);
    // const setupRewardful = () => {
    //     //Rewardful scripts are loaded in the header
    //     window.rewardful("ready", () => {
    //         if (window.Rewardful.referral) {
    //             setReferral(window.Rewardful.referral);
    //         }
    //         if (window.Rewardful.coupon) {
    //             setRewardfulCoupon(window.Rewardful.coupon.id);
    //         }
    //     });
    // };

    const setupPaddle = () => {
        const hashEmail = (email) => {
            // Normalize the email address
            const normalizedEmail = email.trim().toLowerCase();
            
            // Create a SHA-256 hash
            const hash = crypto.createHash('sha256');
            hash.update(normalizedEmail);
            
            // Return the hash as a hex string
            return hash.digest('hex');
        }

        initializePaddle({
            environment: process.env.REACT_APP_PADDLE_ENVIRONMENT,
            token: process.env.REACT_APP_PADDLE_CLIENT_KEY,
            eventCallback: (eventData) => {
                if (eventData.name == "checkout.loaded") {
                    if (window.zaraz) {
                        zaraz.ecommerce("Checkout Started", { 
                            checkout_id: eventData.data.id,
                            order_id: eventData.data.transaction_id,
                            affiliation: eventData.data.custom_data?.affiliate_id,
                            coupon: eventData.data.discount?.code,
                        });
                    }
                } else if (eventData.name == "checkout.customer.created") {
                    if (window.zaraz) {
                        zaraz.set('email', eventData.data.customer.email);
                        zaraz.set('postalCode', eventData.data.customer.address?.postal_code);
                        zaraz.set('region', eventData.data.customer.address?.region);
                    }
                } else if (eventData.name === "checkout.payment.selected") {
                    if (window.zaraz) {
                        zaraz.ecommerce("Payment Info Entered", {
                            checkout_id: eventData.data.id,
                            order_id: eventData.data.transaction_id,
                            affiliation: eventData.data.custom_data?.affiliate_id,
                            coupon: eventData.data.discount?.code,
                            payment_type: eventData.data.payment.method_details?.type
                        });
                    }
                } else if (eventData.name === "checkout.completed") {
                    var email = eventData.data.customer.email;
                    var uid = eventData.data.customer.id;

                    window.fpr("referral", { email, uid })
                    /*global zaraz*/
                    /*eslint no-undef: "error"*/
                    if (window.zaraz) {
                        zaraz.ecommerce("Order Completed", { 
                            checkout_id: eventData.data.id,
                            order_id: eventData.data.transaction_id,
                            affiliation: eventData.data.custom_data?.affiliate_id,
                            total: eventData.data.totals.total,
                            revenue: eventData.data.totals.subtotal,
                            tax: eventData.data.totals.tax,
                            discount: eventData.data.totals.discount,
                            coupon: eventData.data.discount?.code,
                            currency: eventData.data.currency_code,
                        });
                    }
                }
                else if (eventData.name === "checkout.closed") {
                    PubSub.publish("RefreshMembership", true);
                }
            }
        })
        .then((paddleInstance) => {
            if (paddleInstance) {
                setPaddle(paddleInstance);
            }
        });
    }


    useEffect(() => {
        // setupRewardful();
        setupPaddle();
    }, []);

    const showProrateConfirm = (amount, currency, onConfirm) => {
        setOverlayComponent(
        <ProrateOverlay
            amount={amount}
            currency={currency}
            onConfirm={onConfirm}
            onReturn={() => setOverlay("")}
        />
        );
        setOverlay("prorate");
    };

    const showTrialConfirm = (onConfirm) => {
        setOverlayComponent(
        <TrialActiveOverlay
            onConfirm={onConfirm}
            onReturn={() => {setOverlay("");}}
        />
        );
        setOverlay("trial");
    };

    let currentOverlay = overlay;
    if (loading) {
        return (
        <DashboardPanel gap="1rem" icon={<PaymentInfoIcon />} size="wide">
            <LoadingSpinner />
        </DashboardPanel>
        );
    } else if (billing.hasPendingPayment) {
        return (
        <DashboardPanel gap="1rem" icon={<PaymentInfoIcon />} size="wide">
            <PendingPaymentOverlay />
        </DashboardPanel>
        );
    } else if (currentOverlay == "prorate" || currentOverlay == "trial") {
        return (
        <DashboardPanel gap="1rem" icon={<PaymentInfoIcon />} size="wide">
            {overlayComponent}
        </DashboardPanel>
        );
    }

    const activeRecurring = recurring ? recurring.status != "inactive" : false;
    const hasLicense =
        loading &&
        (licenses.active ||
        licenses.upcoming ||
        licenses.perpetual ||
        licenses.lifetime);
    const inTrial = trial.expired == false;
    let showLicense = licenses.lifetime == null && licenses.perpetual == null;

    if (activeRecurring == false) {
        return (
            <DashboardPanel gap="1rem" icon={<PaymentInfoIcon />} size="wide">
                <SubscriptionTitle showDescription={true}/>
                <StartSubscriptionDetail paddle={paddle} userId={userId} showTrialConfirm={showTrialConfirm} trial={trial} email={userEmail}/>
            </DashboardPanel>
        );
    } else if (activeRecurring) {
        return (
            <DashboardPanel gap="1rem" icon={<PaymentInfoIcon />} size="wide">
                <SubscriptionTitle showDescription={false}/>
                <SubscriptionInfoDetail paddle={paddle} recurring={recurring} billing={billing}/>
                <Divider/>
                <ModifySubscriptionDetail recurring={recurring} showProrateConfirm={showProrateConfirm}/>
            </DashboardPanel>
        );
    }
};

const SubscriptionTitle = (props) => {
    return (
        <div className="subscription-container">
            <div className="subscription-copy">
                <h2 className="redtext" style={{ marginBottom: "0.4rem" }}>
                    Subscription
                </h2>
                {props.showDescription == true &&
                    <p><span style={{fontWeight: "bold"}}>Musio All-Access</span> includes all current and upcoming catalog additions</p>
                }
            </div>
        </div>
    );
};


const getBestCouponForType = (coupons, typeToMatch) => {
    let bestCoupon = null;
    if (coupons == null) {
        return bestCoupon;
    }
  
    //check if 'available' in coupons
    if ("available" in coupons) {
      //iterate each available coupon
      for (const coupon of coupons.available) {
        if (coupon.type !== typeToMatch) continue; //skip
  
        //assign the first coupon
        if (bestCoupon === null) {
          bestCoupon = coupon;
        } else {
          // if we already have a bestCoupon, see if there's a better value
          if (parseFloat(bestCoupon.usdValue) < parseFloat(coupon.usdValue)) {
            bestCoupon = coupon;
          }
        }
      }
    }
  
    return bestCoupon;
  };

const renderPriceText = (price, coupon) => {
    if (coupon !== null) {
      const couponPrice = price - coupon.usdValue;
      return (
        <p style={{ marginTop: "0.5rem" }}>
          $9.99/mo <span className="subtext">or </span>$99/yr <br />
          <span className="redtext">
            Coupon: {coupon.description}
          </span>
        </p>
      );
    }
    return (
      <p style={{ marginTop: "0.5rem" }}>
        $9.99/mo <span className="subtext">or </span>$99/yr
      </p>
    );
};



const subscriptionMonthlyPriceId = process.env.REACT_APP_MAA_MONTHLY_PRICE_ID;
const subscriptionAnnualPriceId = process.env.REACT_APP_MAA_ANNUAL_PRICE_ID;

const Divider = (props) => {
    return (
        <div className="or-div">
            <hr className="or-hr" />
        </div>
    );
};

const StartSubscriptionDetail = (props) => {
    const launchCheckout = getLaunchCheckout();
    const [loading, setLoading] = useState(false);
    const [annualBilling, setAnnualBilling] = useState(launchCheckout == "annual");
    
    let paddle = props.paddle;
    let trial = props.trial;
    let userId = props.userId;
    let showTrialConfirm = props.showTrialConfirm;
    let email = props.email;

    let price = 9.99;
    let coupon = getBestCouponForType(props.coupons, "recurring");

    const onClickSubscribe = (e) => {
        e.preventDefault();

        axios.get("api/v1/user/recurring")
        .then(resp => {
            if (resp.data.status != 'inactive')
            {
                PubSub.publish("RefreshMembership", true);
                return;
            }

            if (trial.expired == false) {
                showTrialConfirm(()=>{
                    startMembership();
                })
            }
            else
            {
                startMembership();
            }
        });
    }

    const startMembership = () => {
        var promo_code = null;
        var affiliate_id = null;
        window.fpr("details", (data) => {
            affiliate_id = data.promoter.id
            promo_code = data.promo_code;
        });
        window.setTimeout(()=>{
            const settings = {
                showAddDiscounts: true
            };
            const items = [
                {
                    priceId: annualBilling ? subscriptionAnnualPriceId : subscriptionMonthlyPriceId,
                    quantity: 1
                }
            ];
            const customData = {
                'musioUuid': userId,
                ...(affiliate_id ? {} : { affiliate_id })
            };
            
            let customer = {email};

            paddle?.Checkout.open({settings, items, customData, discountCode: promo_code});
        }, 100);
    };

    if (launchCheckout != undefined) {
        startMembership();
        clearLaunchCheckout();
    }

    return (
        <div className="subscription-container">
            <div className="purchase-btn-container">
                <MusioButton
                    buttonType="squared"
                    loading={loading}
                    text={<span>Subscribe</span>}
                    onClick={onClickSubscribe}
                />
            {/* {renderPriceText(price, coupon)} */}
                <p style={{ marginTop: "0.5rem" }}>
                    {annualBilling ? "$99 / year" : "$9.99 / month"}
                </p>            
            </div>
            <div className='checkbox-flex-center pointer' onClick={
                (e)=>{ setAnnualBilling(!annualBilling); }
            }>
                <MusioCheckbox checked={annualBilling}/>
                <p><span className="subtext">Annual Billing</span>
                    { !annualBilling && " - Save 18%!"}
                </p>
            </div>
        </div>
    )
}

const ModifySubscriptionDetail = (props) => {
    const [loading, setLoading] = useState(false);
    const planToChangeTo = props.recurring.plan === "monthly" ? "annual" : "monthly";
    const planToChangeToDescription = props.recurring.plan === "monthly" ? "Annual" : "Monthly";

    const resumeMembership = (e) => {
        e.preventDefault();
        setLoading(true);
        resumeRecurring().then((resp) => {
            PubSub.publish("RefreshMembership", true);
            setLoading(false);
        });
    };

    const handleChangeMembership = (e, currentPlan, planToChangeTo) => {
        e.preventDefault();
        if (currentPlan === "monthly" && planToChangeTo === "annual") {
            prorateMembership(planToChangeTo);
        } else {
            changeMembership(planToChangeTo);
        }
    };

    const prorateMembership = (plan) => {
        setLoading(true);
        prorateRecurring(plan).then((resp) => {
            setLoading(false);
            props.showProrateConfirm(resp.data.amount, resp.data.currency, () => {
                return changeMembership(plan).then((resp) => {
                    PubSub.publish("RefreshMembership", true);
                });
            });
        });
    };
    
    const changeMembership = (plan) => {
        setLoading(true);
        return changeRecurring(plan, props.referral) //no coupon for changing prices
        .then((resp) => {
            setLoading(false);
            PubSub.publish("RefreshMembership", true);
        });
    };

    const renderActive = () => {
        return (
            <div className="subscription-container">
                <div className="purchase-btn-container">
                    <MusioButton
                        buttonType="squared"
                        loading={loading}
                        text={<span>Update to {planToChangeToDescription} Billing</span>}
                        onClick={(e) =>
                            handleChangeMembership(e, props.recurring.plan, planToChangeTo)
                        }
                    />
                    <p style={{ marginTop: "0.5rem" }}>
                        {props.recurring.plan == "annual" ? <span>$9.99 / month</span> : <span>$99 / year - <span className="redtext">Save 18%!</span></span>}
                    </p>
                </div>
            </div>
        );
    };

    const renderActiveCanceled = () => {
        return (
            <div className="subscription-container">
                <div className="purchase-btn-container">
                    <MusioButton
                        buttonType="squared"
                        loading={loading}
                        text={<span>renew membership</span>}
                        onClick={resumeMembership}
                    />
                    <p style={{ marginTop: "0.5rem" }}>
                        {props.recurring.plan == "monthly" ? "$9.99 / month" : "$99 / year"}
                    </p>
                </div>
            </div>
        );
    };

    if (props.recurring.status == "active" && props.recurring.canceled) {
        return renderActiveCanceled();
    } else if (props.recurring.status == "active") {
        return renderActive();
    }
}

const formatDate = (periodEnd) => {
    let renewalDate = moment(periodEnd, 'MMM DD YYYY').format('MMMM-DD-YYYY');
    renewalDate = renewalDate.replace(/(^|-)0+/g, "$1");
    renewalDate = renewalDate.split('-');
    return `${renewalDate[0]} ${renewalDate[1]}, ${renewalDate[2]}`;
};

const SubscriptionInfoDetail = (props) => {
    const [loading, setLoading] = useState(false);
    const currencySymbol = getSymbolFromCurrency(props.recurring.currency);
    const paddle = props.paddle;

    const updatePayment = (e) => {
        e.preventDefault();

        getPaddlePaymentUpdateTxnId().then((resp) => {
            paddle?.Checkout.open({transactionId: resp.data.txnId});
        })
    }

    const renderActive = () => {
        //if has active sub
        const planToChangeTo = props.recurring.plan === "monthly" ? "annual" : "monthly";
        return (
            <div className="subscription-container">
                <div className="subscription-copy">
                    <h3>Upcoming Charge</h3>
                    <h3 className="upcoming-charge-price">{currencySymbol}{(parseFloat(props.recurring.amount) / 100.0)}</h3>
                    <p className="subtext">due {formatDate(props.recurring.periodEnd)}.</p><br />
                    <a className="musio-link" href="#" onClick={updatePayment}>Update Payment Method</a><br />
                </div>
            </div>
        );

    };
    const renderActiveCanceled = () => {
        const price_text = priceString(props.recurring.plan);
        return (
            <div className="subscription-container">
                <div className="subscription-copy">
                    <h3 className="" style={{ marginBottom: "0.4rem" }}>
                        Cancelation Scheduled
                    </h3>
                    <p className="redtext">expires {formatDate(props.recurring.periodEnd)}</p><br />
                </div>
            </div>
        );
    };

    if (props.recurring.status == "active" && props.recurring.canceled) {
        return renderActiveCanceled();
    } else if (props.recurring.status == "active") {
        return renderActive();
    }
}

const ProrateOverlay = (props) => {
    const [loading, setLoading] = useState(false);
  
    const currencySymbol = getSymbolFromCurrency(props.currency);
    return (
        <div className="prorate-container">
            <div>
                <p className="redtext">you are about to upgrade your plan.</p>
                <p className="subtext">
                    a charge of {currencySymbol}
                    {(parseFloat(props.amount) / 100.0).toFixed(2)} will be made immediately.
                </p>
            </div>
            <div className="confirm-container">
                <MusioButton
                    buttonType="squared"
                    background="grey"
                    onClick={props.onReturn}
                    text="Cancel"
                />
                <MusioButton
                    loading={loading}
                    buttonType="squared"
                    onClick={() => {
                        setLoading(true);
                        props.onConfirm().then(() => props.onReturn());
                    }}
                    text="Confirm"
                />
            </div>
        </div>
    );
};

const TrialActiveOverlay = (props) => {
    const [loading, setLoading] = useState(false);
  
    return (
        <div className="prorate-container">
            <div>
                <p className="redtext">Your free trial is still active!</p>
                <p className="subtext">
                    Are you sure you want to end your trial?
                </p>
            </div>
            <div className="confirm-container">
                <MusioButton
                    buttonType="squared"
                    background="grey"
                    onClick={props.onReturn}
                    text="Cancel"
                />
                <MusioButton
                    loading={loading}
                    buttonType="squared"
                    onClick={() => {
                        setLoading(true);
                        props.onConfirm();
                        props.onReturn();
                    }}
                    text="Confirm"
                />
            </div>
        </div>
    );
};

const PendingPaymentOverlay = (props) => {
    return (
        <div className="payment-pending-container">
            <div>
                <h3>Processing your recent purchase!</h3>
                <br />
                <p className="redtext">Please check back later or refresh the page</p>
                <p className="subtext">
                    NOTE: Some payment methods take 1+ day(s) to authorize and debit. If
                    you believe the process is taking longer than expected please contact
                    support
                </p>
            </div>
        </div>
    );
};

export default SubscriptionPanel;